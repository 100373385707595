import { Search } from "@mui/icons-material";
import { Typography, TextField, Stack, Chip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import { useDomainStageMap } from "@/services/hooks";
import { LABEL_MAP } from "@/fragments/Constants";

export default function SearchDomains({ searchUid }) {
  const navigate = useNavigate();
  const { data, isLoading, isError } = useDomainStageMap({ searchUid });

  if (isLoading) return <Typography>Loading...</Typography>;
  if (isError) return <Typography>Error loading domains</Typography>;

  const options = (data ?? []).map(({ domain, name, stage }) => ({
    domain,
    name,
    stage,
  }));

  const handleChange = (_event, value) => {
    if (value) {
      navigate(`/search?searchKey=${searchUid}&currentView=${value.stage}`);
    }
  };

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) =>
        option.name ? `${option.name} - ${option.domain}` : option.domain
      }
      onChange={handleChange}
      popupIcon={<Search />}
      sx={{ width: 300 }}
      size="small"
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search for target"
          sx={{ "& .MuiOutlinedInput-root": { borderRadius: "30px" } }}
        />
      )}
      // Use renderOption to customize the dropdown options
      renderOption={(props, option) => (
        <li {...props}>
          <Result
            domain={option.domain}
            name={option.name}
            stage={option.stage}
          />
        </li>
      )}
    />
  );
}

function Result({ domain, name, stage }) {
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <Stack>
        <Typography variant="body1">{name || "Unknown Name"}</Typography>
        <Typography variant="caption" color="text.secondary">
          {domain}
        </Typography>
      </Stack>
      <Chip
        label={LABEL_MAP[stage]}
        size="small"
        color="primary"
        variant="outlined"
      />
    </Stack>
  );
}
