import { AddCircle, DataUsage } from "@mui/icons-material";
import { AppBar, Box, Button, IconButton, Toolbar } from "@mui/material";

import SearchBox from "@/components/SearchBox";
import CreateSearch from "@/components/CreateSearch";

import logo from "@/images/logo.svg";
import { useSearches } from "@/services/hooks";

export default function MainAppBar() {
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("actorKey");
    localStorage.removeItem("actorName");
    window.location.reload();
  };

  const { data: searches } = useSearches();

  return (
    <AppBar position="sticky" variant="transparent" sx={{ py: 2 }}>
      <Toolbar sx={{ justifyContent: "space-between" }} disableGutters>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <img
            src={logo}
            style={{ width: "240px" }}
            onClick={() => (window.location.href = "/")}
          />
        </Box>
        {searches && <SearchBox searches={searches} />}

        <CreateSearch />
        <Button color="inherit" onClick={() => handleLogout()}>
          LOGOUT
        </Button>
      </Toolbar>
    </AppBar>
  );
}
