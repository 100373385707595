import React, { useState, useEffect } from "react";
import { Paper, Popper } from "@mui/material";
import CommentsContainer from "@/components/CommentsContainer";

const CommentsPopper = ({ target }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const id = target.domain;

  useEffect(() => {
    // Find and set the anchor element based on the cell's ID
    const element = document.querySelector(`[data-id="${id}"]`);
    setAnchorEl(element);
  }, [id]);

  return (
    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement="bottom-start"
      style={{ zIndex: 1300 }}
    >
      <Paper sx={{ p: 2, width: 600, borderRadius: "25px" }} elevation={10}>
        <CommentsContainer target={target} autofocus={true} />
      </Paper>
    </Popper>
  );
};

export default CommentsPopper;
