import { Box, Card, CardContent, Skeleton } from "@mui/material";

export default function LoadingSkeletonCard() {
  return (
    <Card sx={{ mb: 4 }}>
      <CardContent>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Skeleton
              variant="rectangular"
              width={200}
              height={40}
              sx={{ my: 1 }}
              style={{ borderRadius: "5px" }}
            />
            <Skeleton
              variant="rectangular"
              width={320}
              height={60}
              sx={{ my: 1 }}
              style={{ borderRadius: "5px" }}
            />
          </Box>

          <Skeleton
            variant="rectangular"
            width={420}
            height={60}
            sx={{ my: 1 }}
            style={{ borderRadius: "5px" }}
          />
        </Box>
        <Skeleton
          variant="rectangular"
          width="80%"
          height={300}
          style={{ borderRadius: "5px" }}
        />
      </CardContent>
    </Card>
  );
}
