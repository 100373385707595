import { COUNTRIES, OWNERSHIP, STATES } from "@/fragments/Constants";
import { generateCriteriaTargets, updateSearch } from "@/services/api";
import { Add, Cancel, Close, SettingsSuggest } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Container,
  Drawer,
  IconButton,
  MenuItem,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

export default function GrataDrawer({ searchData }) {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [criteria, setCriteria] = useState(searchData.criteria);
  const searchUid = searchData.uid;

  const mutation = useMutation({
    mutationFn: async () => {
      return await updateSearch(searchUid, { criteria });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["targetCount", Number(searchUid)]);
      generateCriteriaTargets(searchUid);
      setOpen(false);
      setSnackbarOpen(true);
    },
    onError: (error) => {
      console.error("Failed to update search:", error);
    },
  });

  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <Tooltip title="Grata Criteria" placement="left">
          <IconButton onClick={() => setOpen(!open)}>
            <SettingsSuggest color="info" />
          </IconButton>
        </Tooltip>
      </Box>

      {/* Success Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="Generating Targets"
      />

      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          "& .MuiDrawer-paper": {
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            p: 3,
          },
        }}
      >
        <Container size="lg" sx={{ height: "85vh" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <IconButton onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
            <Stack direction="row" spacing={2} alignItems="center">
              <SettingsSuggest />
              <Typography variant="h5">Grata Criteria</Typography>
            </Stack>
            <Button
              variant="contained"
              onClick={mutation.mutate}
              disabled={mutation.isLoading}
            >
              {mutation.isLoading ? "Loading..." : "Save"}
            </Button>
          </Box>

          {/* Replace these with your own criterion inputs */}

          <Stack direction="row" spacing={4} sx={{ mt: 4 }}>
            <Box sx={{ width: "50%" }}>
              <SearchTerms criteria={criteria} setCriteria={setCriteria} />
            </Box>
            <Box sx={{ width: "50%" }}>
              <Ownership criteria={criteria} setCriteria={setCriteria} />
              <EmployeeRange criteria={criteria} setCriteria={setCriteria} />
              <Headquarters criteria={criteria} setCriteria={setCriteria} />
              <SearchTermsExclude
                criteria={criteria}
                setCriteria={setCriteria}
              />
            </Box>
          </Stack>
        </Container>
      </Drawer>
    </Box>
  );
}

function Headquarters({ criteria, setCriteria }) {
  const DEFAULT_LOCATION = { country: "United States", state: "", city: "" };

  const handleInputChange = (event, field, index) => {
    const newCriteria = { ...criteria };
    newCriteria.headquarters.include[index][field] = event.target.value;
    setCriteria(newCriteria);
  };

  const addNewLocation = () => {
    const newCriteria = { ...criteria };
    newCriteria.headquarters.include.push({ ...DEFAULT_LOCATION }); // Use spread to avoid mutation
    setCriteria(newCriteria);
  };

  const removeLocation = (index) => {
    const newCriteria = { ...criteria };
    newCriteria.headquarters.include.splice(index, 1);

    // If no locations left, add back the default location
    if (newCriteria.headquarters.include.length === 0) {
      newCriteria.headquarters.include.push({ ...DEFAULT_LOCATION });
    }

    setCriteria(newCriteria);
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6">Headquarters</Typography>
        <Button
          variant="text"
          onClick={addNewLocation}
          startIcon={<Add />}
          color="info"
        >
          Add New Location
        </Button>
      </Box>

      {criteria.headquarters.include.map((location, index) => (
        <Card
          key={index}
          sx={{
            mt: 2,
            px: 2,
            py: 2,
            position: "relative",
            borderRadius: 4,
          }}
        >
          <IconButton
            onClick={() => removeLocation(index)}
            sx={{ position: "absolute", top: 0, right: 8 }}
          >
            <Cancel sx={{ fontSize: 16, opacity: 0.5 }} />
          </IconButton>

          <Box sx={{ my: 2 }}>
            <TextField
              select
              label="Country"
              size="small"
              value={location.country}
              onChange={(event) => handleInputChange(event, "country", index)}
              fullWidth
            >
              {Object.values(COUNTRIES).map((country) => (
                <MenuItem key={country} value={country}>
                  {country}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          {location.country === "United States" && (
            <>
              <Box sx={{ mb: 2 }}>
                <TextField
                  select
                  size="small"
                  label="State"
                  value={location.state || ""}
                  onChange={(event) => handleInputChange(event, "state", index)}
                  fullWidth
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {Object.values(STATES).map((state) => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              {location.state && (
                <TextField
                  label="City"
                  size="small"
                  value={location.city || ""}
                  onChange={(event) => handleInputChange(event, "city", index)}
                  fullWidth
                  sx={{ mb: 2 }}
                />
              )}
            </>
          )}
        </Card>
      ))}
    </Box>
  );
}

function Ownership({ criteria, setCriteria }) {
  const handleOwnershipChange = (event, value) => {
    setCriteria({
      ...criteria,
      ownership: value,
    });
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Autocomplete
        multiple
        options={OWNERSHIP}
        value={criteria.ownership}
        onChange={handleOwnershipChange}
        size="small"
        renderInput={(params) => <TextField {...params} label="Ownership" />}
        getOptionLabel={(option) => option}
      />
    </Box>
  );
}

function EmployeeRange({ criteria, setCriteria }) {
  const handleRangeChange = (event, index) => {
    const newRange = [...criteria.grata_employees_estimates_range];
    newRange[index] = event.target.value ? parseInt(event.target.value, 10) : 0;
    setCriteria({
      ...criteria,
      grata_employees_estimates_range: newRange,
    });
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography sx={{ ml: 1, color: "darkgrey" }} variant="caption">
        Employee Estimate Range
      </Typography>
      <Box sx={{ display: "flex", gap: 2, mt: 1 }}>
        <TextField
          label="Min"
          type="number"
          size="small"
          value={criteria.grata_employees_estimates_range[0]}
          onChange={(event) => handleRangeChange(event, 0)}
          fullWidth
        />
        <TextField
          label="Max"
          type="number"
          size="small"
          // helperText="0 for no limit"
          value={criteria.grata_employees_estimates_range[1]}
          onChange={(event) => handleRangeChange(event, 1)}
          fullWidth
        />
      </Box>
    </Box>
  );
}

function SearchTerms({ criteria, setCriteria }) {
  const handleInputChange = (event, field, index) => {
    const newCriteria = { ...criteria };
    if (field === "terms") {
      console.log("event.target.value", event.target.value);

      newCriteria.terms_include.groups[index].terms = event.target.value
        .split(", ")
        .map((term) => term);
      console.log(newCriteria.terms_include.groups[index].terms);
    } else if (field === "terms_depth") {
      newCriteria.terms_include.groups[index].terms_depth = event.target.value;
    } else if (field === "terms_operator") {
      newCriteria.terms_include.groups[index].terms_operator =
        event.target.value;
    } else if (field === "group_operator") {
      newCriteria.terms_include.group_operator = event.target.value;
    }
    setCriteria(newCriteria);
  };

  // const handleTermsChange = (event, index) => {

  const addNewGroup = () => {
    const newCriteria = { ...criteria };
    newCriteria.terms_include.groups.push({
      terms: [],
      terms_depth: "core",
      terms_operator: "any",
    });
    setCriteria(newCriteria);
  };

  const removeGroup = (index) => {
    const newCriteria = { ...criteria };
    newCriteria.terms_include.groups.splice(index, 1);
    setCriteria(newCriteria);
  };

  return (
    <Box>
      {/* Terms Include Group */}
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">Search Terms</Typography>
          <Button
            variant="text"
            onClick={addNewGroup}
            startIcon={<Add />}
            color="info"
          >
            Add New Group
          </Button>
        </Box>

        {criteria.terms_include.groups.map((group, index) => (
          <Card
            key={index}
            sx={{
              mt: 2,
              px: 2,
              py: 2,
              position: "relative",
              borderRadius: 4,
            }}
          >
            <IconButton
              onClick={() => removeGroup(index)}
              sx={{ position: "absolute", top: 0, right: 8 }}
            >
              <Cancel sx={{ fontSize: 16, opacity: 0.5 }} />
            </IconButton>
            <TextField
              label="Terms (comma-separated)"
              variant="outlined"
              size="small"
              fullWidth
              multiline
              value={group.terms.join(", ")}
              // value={group.terms}
              onChange={(event) => handleInputChange(event, "terms", index)}
              sx={{ my: 2 }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              <TextField
                select
                label="Terms Depth"
                size="small"
                value={group.terms_depth}
                onChange={(event) =>
                  handleInputChange(event, "terms_depth", index)
                }
                fullWidth
              >
                <MenuItem value="core">Core</MenuItem>
                <MenuItem value="secondary">Secondary</MenuItem>
              </TextField>
              <TextField
                select
                label="Terms Operator"
                size="small"
                value={group.terms_operator}
                onChange={(event) =>
                  handleInputChange(event, "terms_operator", index)
                }
                fullWidth
              >
                <MenuItem value="any">Any</MenuItem>
                <MenuItem value="all">All</MenuItem>
              </TextField>
            </Box>
          </Card>
        ))}
      </Box>

      {/* Group Operator */}
      <Box sx={{ mt: 4 }}>
        <TextField
          select
          size="small"
          label="Group Operator"
          value={criteria.terms_include.group_operator}
          onChange={(event) => handleInputChange(event, "group_operator")}
          sx={{ mb: 2, width: "100%" }}
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="any">Any</MenuItem>
        </TextField>
      </Box>

      {/* Add New Group Button */}
    </Box>
  );
}

function SearchTermsExclude({ criteria, setCriteria }) {
  const handleInputChange = (event) => {
    const newCriteria = { ...criteria };
    // Split the input string by commas, trim any extra spaces, and update the terms_exclude array
    newCriteria.terms_exclude = event.target.value
      .split(", ")
      .map((term) => term);
    // .filter((term) => term.length > 0); // Filter out empty terms
    setCriteria(newCriteria);
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6" color="textSecondary">
        Exclude Terms
      </Typography>
      <TextField
        label="Exclude Terms (comma-separated)"
        variant="outlined"
        size="small"
        fullWidth
        value={criteria.terms_exclude.join(", ")} // Join the terms array back into a string for display
        onChange={handleInputChange}
        sx={{ my: 2 }}
      />
    </Box>
  );
}
