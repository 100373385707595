import { useNavigate, useSearchParams } from "react-router-dom";

import { Button, Tooltip } from "@mui/material";

import { Add } from "@mui/icons-material";

import { useSearchData } from "@/services/hooks";

export default function PageAppBar() {
  const [searchParams] = useSearchParams();
  const currentView = searchParams.get("currentView");
  const navigate = useNavigate();

  const { data: searchData } = useSearchData({
    uid: searchParams.get("searchKey"),
  });

  const runCriteriaSearch = async () => {
    console.log("Running criteria search...");
  };

  // const runCriteriaSearch = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await axios.post(API_BASE + "/criteria_search", {
  //       search_uid: searchData.uid,
  //       // criteria: criteria, // this is really "new" criteria...
  //     });

  //     console.log(currentView);
  //     if (currentView !== "create") {
  //       window.location.href = `search?searchKey=${searchData.uid}&currentView=create`;
  //     }
  //     console.log("Search Results:", response.data);
  //     fetchTargetCount();
  //     fetchTargets();
  //   } catch (error) {
  //     console.error("Error running criteria search:", error);
  //     // setError("Failed to run search. Please try again.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return <></>;

  return (
    <Tooltip title="Deliver next 25 results from Grata based on the current criteria">
      <Button
        variant="outlined"
        color="auto"
        onClick={runCriteriaSearch}
        // disabled={loading}
        startIcon={<Add />}
        sx={{ borderRadius: 20 }}
      >
        Next 25 Results
      </Button>
    </Tooltip>
  );
}
