import React from "react";
import { ICON_MAP } from "@/fragments/Constants";

export const getFitColor = (fit) => {
  switch (fit) {
    case "All":
      return "info";
    case "Good fit":
      return "success";
    case "Potential fit":
      return "auto";
    case "Not a fit":
      return "error";
    case "Unsure":
      return "warning";
    default:
      return "disabled";
  }
};

export const getCurrentUserFirstName = () => {
  const actorName = localStorage.getItem("actorName");
  // now handle first name
  const firstName = actorName.split(" ")[0];
  return firstName;
};

export const getIcon = (stage, color) => {
  const icon = ICON_MAP[stage];

  if (!icon) {
    console.warn(`No icon found for stage: ${stage}`);
    return null;
  }

  return React.cloneElement(icon, { color: color });
};

export const clearColumnOrder = (searchUid) => {
  localStorage.removeItem(`${searchUid}_columnOrder`);
};

export const setColumnOrder = (searchUid, columnOrder) => {
  localStorage.setItem(searchUid, JSON.stringify(columnOrder));
};

export const getCompactState = () => {
  return JSON.parse(localStorage.getItem("compact")) || false;
};

export const toggleCompact = () => {
  const compact = getCompactState();
  localStorage.setItem("compact", JSON.stringify(!compact));
  window.location.reload();
};

export const getApiBase = () => {
  const url = window.location.href;
  if (url.includes("staging")) {
    return "https://api-dot-targetselect-staging.uc.r.appspot.com";
  } else if (url.includes("dev")) {
    console.log("dev API");
    return "https://api-dot-targetselect-dev.uc.r.appspot.com";
  } else if (url.includes("localhost")) {
    return "http://localhost:8080";
  } else {
    return "https://api-dot-targetselect-prod.uc.r.appspot.com";
  }
};

export const getWeekColor = (weekChar) => {
  const currentWeekNumber = Math.ceil(
    (new Date().getTime() -
      new Date(new Date().getFullYear(), 0, 1).getTime()) /
      (1000 * 60 * 60 * 24 * 7)
  );
  const currentWeekChar = currentWeekNumber % 2 === 0 ? "A" : "B";
  return currentWeekChar === weekChar ? "primary" : "info";
};
