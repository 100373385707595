import { ICON_MAP, LABEL_MAP } from "@/fragments/Constants";
import { importTargets, moveTargets } from "@/services/api";
import { useDomainStageMap } from "@/services/hooks";
import { ExpandLess, ExpandMore, Info } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ResultCard from "./ResultCard";

export default function ResultsHandler({
  domains,
  importStage,
  source,
  setOpen,
  setCsv,
}) {
  const [searchParams] = useSearchParams();
  const searchUid = searchParams.get("searchKey");
  const queryClient = useQueryClient();

  const [results, setResults] = useState({});
  const [showNewDomains, setShowNewDomains] = useState(false);
  const [expandedSections, setExpandedSections] = useState({});

  const {
    data: domainStageMap,
    isLoading,
    isError,
  } = useDomainStageMap({ searchUid });

  useEffect(() => {
    if (!domains?.length || isLoading || isError) return;
    const newMap = {};
    domains.forEach((d) => {
      newMap[d] = domainStageMap?.[d] || "new";
    });
    setResults(newMap);
  }, [domains, domainStageMap, isLoading, isError]);

  const conflictedResults = Object.entries(results)
    .filter(([, st]) => st !== "new")
    .sort((a, b) => a[1].localeCompare(b[1]));
  const newDomains = Object.entries(results)
    .filter(([, st]) => st === "new")
    .map(([d]) => d);

  // Mutation for importing
  const { mutate: importMutate, isLoading: importLoading } = useMutation({
    mutationFn: () => importTargets(searchUid, newDomains, importStage, source),
    onSuccess: () => {
      queryClient.invalidateQueries(["targets", Number(searchUid)]);
      queryClient.invalidateQueries(["targetCount", Number(searchUid)]);
      queryClient.invalidateQueries(["domainStageMap", searchUid]);
      setCsv("");
      setOpen(false);
    },
  });

  // Mutation for moving
  const { mutate: moveMutate, isLoading: moveLoading } = useMutation({
    mutationFn: (selectedDomains) =>
      moveTargets(searchUid, selectedDomains, importStage),
    onSuccess: () => {
      queryClient.invalidateQueries(["targets", Number(searchUid)]);
      queryClient.invalidateQueries(["targetCount", Number(searchUid)]);
      queryClient.invalidateQueries(["domainStageMap", Number(searchUid)]);
    },
  });

  const handleImportTargets = () => {
    importMutate();
  };

  const handleSectionToggle = (stage) => {
    setExpandedSections((prev) => ({ ...prev, [stage]: !prev[stage] }));
  };

  const handleMoveTargets = (fromStage) => {
    const selected = conflictedResults
      .filter(([, st]) => st === fromStage)
      .map(([d]) => d);
    moveMutate(selected);
  };

  return (
    <Box sx={{ my: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="contained"
          onClick={handleImportTargets}
          disabled={!newDomains.length || importLoading}
          sx={{ my: 2, borderRadius: "20px", flex: 1, mr: 1 }}
          size="large"
        >
          {importLoading
            ? "Importing..."
            : !newDomains.length
            ? "No New Targets"
            : `Import ${newDomains.length} New ${
                newDomains.length === 1 ? "Target" : "Targets"
              }`}
        </Button>

        <IconButton onClick={() => setShowNewDomains((x) => !x)} color="info">
          <Info />
        </IconButton>
      </Box>

      {showNewDomains && !!newDomains.length && (
        <Box sx={{ mt: 2, mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            New Domains:
          </Typography>
          <Box component="ul" sx={{ pl: 2 }}>
            {newDomains.map((d) => (
              <li key={d}>{d}</li>
            ))}
          </Box>
        </Box>
      )}

      {conflictedResults.length > 0 && (
        <>
          <Typography variant="h6" gutterBottom>
            Targets in Search ({conflictedResults.length})
          </Typography>
          {Object.keys(LABEL_MAP).map((stage) => {
            const stageResults = conflictedResults.filter(
              ([, st]) => st === stage
            );
            if (!stageResults.length) return null;

            return (
              <Box key={stage} sx={{ my: 2 }}>
                <Button
                  onClick={() => handleSectionToggle(stage)}
                  sx={{
                    width: "100%",
                    justifyContent: "space-between",
                    px: 2,
                    py: 1,
                    mb: 1,
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.08)" },
                  }}
                >
                  <Typography variant="h6">
                    {LABEL_MAP[stage]} ({stageResults.length})
                  </Typography>
                  {expandedSections[stage] ? <ExpandLess /> : <ExpandMore />}
                </Button>
                <Collapse in={expandedSections[stage]} timeout={0}>
                  <Box sx={{ pl: 2 }}>
                    <Button
                      variant="contained"
                      sx={{ my: 1, borderRadius: "20px" }}
                      endIcon={ICON_MAP[importStage]}
                      color="primary"
                      onClick={() => handleMoveTargets(stage)}
                      disabled={importStage === stage || moveLoading}
                    >
                      {moveLoading
                        ? "Moving..."
                        : `Move All (${stageResults.length}) to ${LABEL_MAP[importStage]}`}
                    </Button>
                    {stageResults.map(([d]) => (
                      <ResultCard
                        key={d}
                        domain={d}
                        currentStage={stage}
                        importStage={importStage}
                        searchUid={searchUid}
                        source={source}
                      />
                    ))}
                  </Box>
                </Collapse>
              </Box>
            );
          })}
        </>
      )}

      {isLoading && (
        <Box sx={{ mt: 2, textAlign: "center" }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}
