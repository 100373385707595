import { Helmet } from "react-helmet";

const getHelmetTitle = (searchData) => {
  let title = searchData?.label || "TargetSelect.ai";
  if (window.location.origin.includes("localhost")) {
    title = "[dev] " + title;
  } else if (window.location.origin.includes("staging")) {
    title = "[staging] " + title;
  }
  return title;
};

export default function HelmetTitle({ searchData }) {
  return (
    <Helmet>
      <title>{getHelmetTitle(searchData)}</title>
    </Helmet>
  );
}
