import { useEffect, useState } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  Container,
  Drawer,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Close, JoinInner, Save } from "@mui/icons-material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import EventHistoryContainer from "@/components/EventHistoryContainer";
import { updateSearch } from "@/services/api";
import { useActors } from "@/services/hooks";
import { SEARCH_TYPES } from "@/fragments/Constants";

export default function NotesDrawer({ searchData }) {
  const { data: actors } = useActors();
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    notes: "",
    sources: "",
    products: "",
    services: "",
    endMarket: "",
    geographies: "",
    customColumns: [],
    businessType: "",
    type: "",
    size: "",
    analyst: "",
    lead: "",
    domain: "", // Add domain
  });

  useEffect(() => {
    if (!searchData) return;
    setFormData({
      notes: searchData.meta?.notes || "",
      sources: searchData.meta?.sources || "",
      products: searchData.meta?.products || "",
      services: searchData.meta?.services || "",
      endMarket: searchData.meta?.end_market || "",
      geographies: searchData.meta?.geographies || "",
      customColumns: searchData.meta?.custom_columns || [],
      businessType: searchData.meta?.business_type || "",
      type: searchData.meta?.type || "",
      size: searchData.meta?.size || "",
      analyst: searchData.analyst?.key || "",
      lead: searchData.lead?.key || "",
      domain: searchData.meta?.domain || "", // Populate domain
    });
    if (searchData.meta.notes === "") setOpen(true);
  }, [searchData]);

  const { mutate: saveSearch, isLoading } = useMutation({
    mutationFn: async (data) => {
      return updateSearch(searchData.uid, {
        meta: {
          notes: data.notes,
          sources: data.sources,
          products: data.products,
          services: data.services,
          end_market: data.end_market,
          geographies: data.geographies,
          custom_columns: data.custom_columns,
          business_type: data.business_type,
          type: data.type,
          size: data.size,
          domain: data.domain, // Pass domain
        },
        analyst_key: data.analyst_key,
        lead_key: data.lead_key,
      });
    },
    onSuccess: () => {
      setError(null);
      queryClient.invalidateQueries(["searches"]);
      queryClient.invalidateQueries(["search", Number(searchData.uid)]);
      setOpen(false);
    },
    onError: (error) => {
      setError(error.message || "Failed to update search. Please try again.");
    },
  });

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    saveSearch({
      ...formData,
      end_market: formData.endMarket,
      geographies: formData.geographies,
      custom_columns: formData.customColumns,
      business_type: formData.businessType,
      analyst_key: formData.analyst,
      lead_key: formData.lead,
    });
  };

  return (
    <Box>
      {/* Button to open the drawer */}
      <Tooltip title="Strategy" placement="left">
        <IconButton color="info" onClick={() => setOpen(true)}>
          <JoinInner color="auto" />
        </IconButton>
      </Tooltip>

      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          "& .MuiDrawer-paper": {
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            p: 3,
          },
        }}
      >
        <Container maxWidth="xl" sx={{ maxHeight: "85vh" }}>
          {/* Header */}
          <Stack
            sx={{ my: 4 }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <IconButton onClick={() => setOpen(false)}>
              <Close />
            </IconButton>

            <Stack direction="row" spacing={2} alignItems="center">
              <JoinInner color="auto" />
              <Typography variant="h5" sx={{ mt: 2 }}>
                Strategy
              </Typography>
            </Stack>

            <Button
              variant="contained"
              startIcon={<Save />}
              onClick={handleSave}
              disabled={isLoading}
              sx={{ borderRadius: 30 }}
            >
              Save
            </Button>
          </Stack>

          {/* Error alert */}
          {error && (
            <Alert
              severity="error"
              onClose={() => setError(null)}
              sx={{ mb: 2 }}
            >
              {error}
            </Alert>
          )}

          {/* Fields */}
          <Stack direction="row" spacing={8} sx={{ mt: 8 }}>
            {/* Left column */}
            <Stack spacing={4} sx={{ mt: 2, width: "50%" }}>
              <TextField
                name="notes"
                label="Notes and context"
                required
                placeholder="Add context"
                multiline
                minRows={4}
                maxRows={20}
                value={formData.notes}
                onChange={handleFieldChange}
                autoFocus
                color="auto"
              />

              <TextField
                name="sources"
                label="Sources"
                helperText="Sources reviewed"
                multiline
                maxRows={8}
                value={formData.sources}
                onChange={handleFieldChange}
              />

              <Autocomplete
                multiple
                freeSolo
                options={[]}
                value={formData.customColumns}
                onChange={(_, newValue) =>
                  setFormData((prev) => ({ ...prev, customColumns: newValue }))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Custom Columns"
                    placeholder="Add columns"
                  />
                )}
                sx={{ mb: 4 }}
              />

              <Typography variant="h6" sx={{ mt: 2 }}>
                Ownership
              </Typography>
              <Stack direction="row" spacing={4} sx={{ my: 2 }}>
                <TextField
                  name="analyst"
                  select
                  label="Analyst"
                  value={formData.analyst}
                  onChange={handleFieldChange}
                  helperText="Select the analyst"
                  fullWidth
                >
                  {actors?.map((actor) => (
                    <MenuItem key={actor.key} value={actor.key}>
                      {actor.name}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  name="lead"
                  select
                  label="Deal Lead"
                  value={formData.lead}
                  onChange={handleFieldChange}
                  helperText="Select the deal lead"
                  fullWidth
                >
                  {actors?.map((actor) => (
                    <MenuItem key={actor.key} value={actor.key}>
                      {actor.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
            </Stack>

            {/* Right column */}
            <Stack spacing={4} sx={{ mt: 2, width: "50%" }}>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
                <TextField
                  name="type"
                  label="Search Type"
                  select
                  value={formData.type}
                  onChange={handleFieldChange}
                  sx={{ width: 320, mb: 2 }}
                >
                  {SEARCH_TYPES.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </TextField>

                {/* Only show domain if type === "seller" */}
                {formData.type === "Seller" && (
                  <TextField
                    name="domain"
                    label="Domain"
                    value={formData.domain}
                    onChange={handleFieldChange}
                    sx={{ width: 320, mb: 2 }}
                  />
                )}

                <TextField
                  name="size"
                  label="Size"
                  helperText="e.g. rev, employees"
                  multiline
                  value={formData.size}
                  onChange={handleFieldChange}
                  sx={{ width: 320, mb: 2 }}
                />

                <TextField
                  name="products"
                  label="Products"
                  helperText="Comma-separated"
                  multiline
                  value={formData.products}
                  onChange={handleFieldChange}
                  sx={{ width: 320, my: 2 }}
                />

                <TextField
                  name="services"
                  label="Services"
                  helperText="Comma-separated"
                  multiline
                  value={formData.services}
                  onChange={handleFieldChange}
                  sx={{ width: 320, my: 2 }}
                />

                <TextField
                  name="endMarket"
                  label="End Market"
                  helperText="Comma-separated"
                  multiline
                  value={formData.endMarket}
                  onChange={handleFieldChange}
                  sx={{ width: 320, my: 2 }}
                />

                <TextField
                  name="businessType"
                  label="Business Type"
                  helperText="Manufacturer, Distributor, Service"
                  value={formData.businessType}
                  onChange={handleFieldChange}
                  sx={{ width: 320, my: 2 }}
                />

                <TextField
                  name="geographies"
                  label="Geographies"
                  helperText="Comma-separated"
                  multiline
                  value={formData.geographies}
                  onChange={handleFieldChange}
                  sx={{ width: 320, my: 2 }}
                />
              </Box>
            </Stack>
          </Stack>

          <Typography variant="h6" sx={{ mt: 8 }}>
            Search History
          </Typography>
          <EventHistoryContainer searchData={searchData} open={open} />
        </Container>
      </Drawer>
    </Box>
  );
}
