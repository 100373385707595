// ImportDrawer component
import { ICON_MAP, LABEL_MAP, SOURCE_OPTIONS } from "@/fragments/Constants";
import { AddCircle, KeyboardDoubleArrowRight } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Container,
  Drawer,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { parse } from "tldts";
import ResultsHandler from "./ResultsHandler";
import { useSearchParams } from "react-router-dom";

function cleanDomain(rawUrl) {
  if (!rawUrl) return "";
  const trimmed = rawUrl.trim();
  const parsed = parse(trimmed, { allowPrivateDomains: true });
  return parsed.domain || ""; // e.g., "amazon.com"
}
export default function ImportDrawer() {
  const [searchParams] = useSearchParams();

  const [open, setOpen] = useState(false);
  const [importStage, setImportStage] = useState(
    searchParams.get("currentView") || "land"
  );
  const [source, setSource] = useState([]);
  const [csv, setCsv] = useState("");

  const handleClose = () => setOpen(false);

  const domains = useMemo(() => {
    return csv
      .split("\n")
      .map((line) => cleanDomain(line))
      .filter(Boolean);
  }, [csv]);

  return (
    <Box>
      <Tooltip title="Import" placement="left">
        <IconButton color="secondary" onClick={() => setOpen(true)}>
          <AddCircle />
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { width: "700px" } }}
      >
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", left: 10, top: 10 }}
          color="info"
        >
          <KeyboardDoubleArrowRight />
        </IconButton>

        <Container sx={{ my: 8 }}>
          <Typography variant="h5" gutterBottom>
            Import Targets
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
            <Box sx={{ width: "100%" }}>
              <TextField
                select
                value={importStage}
                onChange={(e) => setImportStage(e.target.value)}
                required
                label="Stage"
                variant="outlined"
                fullWidth
                sx={{ my: 2 }}
              >
                {Object.keys(LABEL_MAP).map((key) => (
                  <MenuItem key={key} value={key}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                      {ICON_MAP[key]}
                      {LABEL_MAP[key]}
                    </Box>
                  </MenuItem>
                ))}
              </TextField>

              <Autocomplete
                multiple
                freeSolo
                value={source}
                onChange={(e, newValue) => setSource(newValue)}
                options={SOURCE_OPTIONS}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Data Source"
                    variant="outlined"
                    helperText="Where did this come from? Select or type + enter."
                    sx={{ my: 2 }}
                  />
                )}
              />

              <TextField
                value={csv}
                onChange={(e) => setCsv(e.target.value)}
                required
                helperText="Add one domain per line / paste from spreadsheet"
                label="Copy/paste domain(s) here"
                autoComplete="off"
                variant="outlined"
                fullWidth
                multiline
                minRows={2}
                maxRows={8}
              />
            </Box>
          </Box>
          <ResultsHandler
            domains={domains}
            source={source}
            importStage={importStage}
            setOpen={setOpen}
            setCsv={setCsv}
          />
        </Container>
      </Drawer>
    </Box>
  );
}
