import CommentsContainer from "@/components/CommentsContainer";
import RatingContainer from "@/components/RatingContainer";
import { AutoAwesome, JoinInner, LinkedIn } from "@mui/icons-material";
import {
  Box,
  Card,
  CircularProgress,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import Highlighter from "react-highlight-words";
import ChipRow from "./ChipRow";
import EventButtonsContainer from "./EventButtonsContainer";
import { getFitColor } from "@/utils";
import EnrichTargetButton from "@/components/EnrichTargetButton";
import GetSimiliarButton from "@/components/GetSimilarButton";

export default function TargetCard({ searchData, target }) {
  const isLimited = () => {
    // return false;
    return ["create", "land", "similar"].includes(target.stage);
  };

  return (
    <Card
      variant="outlined"
      key={target.domain}
      sx={{ p: 4, my: 4, backgroundColor: "rgba(36,36,36,0.7)" }}
    >
      <Box
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {!isLimited() && (
            <>
              <Link
                href={`https://www.${target.domain}`}
                target="_blank"
                color="info"
                sx={{ mr: 2 }}
              >
                {target.domain}
              </Link>
              {target["linkedin"] && (
                <IconButton
                  href={target["linkedin"]}
                  target="_blank"
                  sx={{ p: 0 }}
                >
                  <LinkedIn sx={{ fontSize: "20px", mx: 1, opacity: 0.7 }} />
                </IconButton>
              )}
            </>
          )}

          <Typography variant="h5" sx={{ fontWeight: 600, mr: 2 }}>
            {target.name}
          </Typography>

          <Box sx={{ mx: 2 }}>
            <EnrichTargetButton target={target} />
          </Box>

          <GetSimiliarButton target={target} />
        </Box>

        <EventButtonsContainer target={target} />
      </Box>

      {!target.gpt_description && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 8 }}>
          <CircularProgress />
        </Box>
      )}

      {target.gpt_description && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}>
          <ChipRow searchData={searchData} target={target} />
          <TargetDescription target={target} />
          <FitCheck target={target} />

          <WasAcquired target={target} />
          <RatingContainer target={target} />
        </Box>
      )}

      <CommentsContainer searchData={searchData} target={target} />
    </Card>
  );
}

function FitCheck({ target }) {
  if (!target.fit) {
    return null;
  }

  return (
    <Box
      id="fit-description"
      style={{
        wordWrap: "break-word",
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      <JoinInner
        sx={{ fontSize: "1.2em", mr: 1, mt: 0.2 }}
        color={getFitColor(target.fit)}
      />
      <Typography
        variant="body"
        sx={{
          flexShrink: 0, // This prevents the element from shrinking
        }}
      >
        {target.fit}
      </Typography>
      <Typography variant="body" sx={{ ml: 2 }}>
        {target.fit_reason}
      </Typography>
    </Box>
  );
}

function TargetDescription({ target }) {
  return (
    <Box id="target-description" style={{ wordWrap: "break-word" }}>
      <AutoAwesome sx={{ fontSize: "1.2em", mr: 1 }} color="info" />
      <Highlighter
        // searchWords={keywords || []}
        searchWords={[]}
        autoEscape={true}
        textToHighlight={target.description || ""}
      />
    </Box>
  );
}

function WasAcquired({ target }) {
  if (target.was_acquired != "Yes") {
    return null;
  }

  return (
    <>
      {target.meta.was_acquired != "" && (
        <Box style={{ width: "100%" }}>
          <Typography variant="caption" color="lightgray">
            was_acquired
          </Typography>
          <Typography variant="body" style={{ marginLeft: "15px" }}>
            {target.meta.was_acquired}
          </Typography>
          <Typography variant="caption" style={{ marginLeft: "15px" }}>
            {target.meta.justification}
          </Typography>
        </Box>
      )}
    </>
  );
}
