import EditTextarea from "@/components/EditTextArea";
import { SEARCH_TYPES } from "@/fragments/Constants";
import { getWeekColor } from "@/utils";
import { fromNow } from "@/utils/time";
import { Box, Chip, Link } from "@mui/material";

const renderCommaChips = (value, color = "default") => {
  if (!value) return null;
  const strValue = typeof value === "string" ? value : value.toString();
  return (
    <Box>
      {strValue.split(",").map((val, i) => (
        <Chip
          key={i}
          label={val.trim()}
          size="small"
          color={color}
          sx={{ mr: 0.5 }}
        />
      ))}
    </Box>
  );
};

export const getColumns = (actors) => [
  {
    field: "analyst",
    headerName: "Analyst",
    minWidth: 120,
    renderCell: ({ value }) => value?.name ?? "",
    editable: true,
    type: "singleSelect",
    valueOptions: actors?.map((actor) => actor.name),
  },
  {
    field: "lead",
    headerName: "Deal Lead",
    minWidth: 120,
    renderCell: ({ value }) => value?.name ?? "",
    editable: true,
    type: "singleSelect",
    valueOptions: actors?.map((actor) => actor.name),
  },
  {
    field: "week",
    editable: true,
    type: "singleSelect",
    valueOptions: ["A", "B"],
    renderCell: ({ row }) =>
      row.week && (
        <Chip label={row.week} size="small" color={getWeekColor(row.week)} />
      ),
  },
  {
    field: "day",
    editable: true,
    type: "singleSelect",
    valueOptions: ["1-Mon.", "2-Tue.", "3-Wed.", "4-Thu.", "5-Fri."],
  },
  {
    field: "status",
    width: 120,
    editable: true,
    type: "singleSelect",
    valueOptions: [
      "starting",
      "active",
      "holding",
      "blocked",
      "completed",
      "paused",
      "cancelled",
    ],
  },
  {
    field: "label",
    headerName: "search",
    width: 220,
    renderCell: ({ row }) => (
      <Link
        href={`/search?searchKey=${row.uid}&currentView=land`}
        style={{ textDecoration: "none", color: "#04FFFF" }}
      >
        {row.label}
      </Link>
    ),
  },
  {
    field: "type",
    editable: true,
    type: "singleSelect",
    valueOptions: SEARCH_TYPES,
  },
  {
    field: "business_type",
    editable: true,
    renderEditCell: (params) => <EditTextarea {...params} />,
    renderCell: ({ value }) => renderCommaChips(value, "info"),
  },
  {
    field: "products",
    flex: 1,
    editable: true,
    renderEditCell: (params) => <EditTextarea {...params} />,
    renderCell: ({ value }) => renderCommaChips(value, "primary"),
  },
  {
    field: "services",
    flex: 1,
    editable: true,
    renderEditCell: (params) => <EditTextarea {...params} />,
    renderCell: ({ value }) => renderCommaChips(value, "success"),
  },
  {
    field: "end_market",
    flex: 1,
    editable: true,
    renderEditCell: (params) => <EditTextarea {...params} />,
    renderCell: ({ value }) => renderCommaChips(value, "secondary"),
  },
  {
    field: "geographies",
    flex: 1,
    editable: true,
    renderEditCell: (params) => <EditTextarea {...params} />,
    renderCell: ({ value }) => renderCommaChips(value, "info"),
  },
  {
    field: "size",
    editable: true,
    width: 80,
  },
  {
    field: "notes",
    editable: true,
  },
  {
    field: "updated",
    width: 100,
    type: "number",
    valueGetter: (value) => {
      return typeof value === "number" ? value : Date.now() / 1000;
    },
    valueFormatter: (value) => fromNow(value),
  },
  // I want a MoreHorizIcon here
];
