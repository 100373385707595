import { Box } from "@mui/material";
import Consistency from "./Consistency";
import Leaderboard from "./Leaderboard";
import SearchVolumes from "./SearchVolumes";
import Time from "./Time";
import MainAppBar from "@/components/MainAppBar";
import SearchesGrid from "@/components/SearchesGrid";

export default function Reporting({}) {
  return (
    <Box
      sx={{
        pb: 10,
        px: 4,
        background:
          "linear-gradient(180deg, rgba(18,18,18,0) 0%, rgba(36,36,36,1) 100%)",
      }}
    >
      <MainAppBar />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Leaderboard />
        <SearchesGrid />
        <SearchVolumes />
        <Consistency />
        <Time />
      </Box>
    </Box>
  );
}
