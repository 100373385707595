import { fromNow } from "@/utils/time";
import { ArrowUpward, ClearOutlined } from "@mui/icons-material";
import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { postComment, deleteComment } from "@/services/api";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { getCurrentUserFirstName } from "@/utils";

export default function CommentsContainer({ target, autofocus = false }) {
  const [comment, setComment] = useState("");
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (commentText) => postComment(target.id, commentText),
    onMutate: async (newComment) => {
      await queryClient.cancelQueries({
        queryKey: ["targets", target.search_uid, target.stage],
      });

      const previousTargets = queryClient.getQueryData([
        "targets",
        target.search_uid,
        target.stage,
      ]);

      queryClient.setQueryData(
        ["targets", target.search_uid, target.stage],
        (old) =>
          old?.map((t) => {
            if (t.id === target.id) {
              return {
                ...t,
                comments: [
                  {
                    // id: `temp-${Date.now()}`,
                    text: newComment,
                    created: Date.now(),
                    author: getCurrentUserFirstName(),
                  },
                  ...t.comments,
                ],
              };
            }
            return t;
          })
      );

      return { previousTargets };
    },
    onError: (err, newComment, context) => {
      queryClient.setQueryData(
        ["targets", target.search_uid, target.stage],
        context.previousTargets
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["targets", target.search_uid, target.stage],
      });
    },
  });

  const submitComment = () => {
    if (!comment.trim()) return;
    const commentText = comment; // Store the comment text
    setComment(""); // Clear immediately
    mutation.mutate(commentText); // Use the stored text
  };

  const handleCommentChange = (e) => {
    const newVal = e.target.value.trimStart();
    // remove newlines and spaces from the front only
    // for the sake of the Comments Popper in Grid View
    setComment(newVal);
  };

  return (
    <Box sx={{ my: 2 }}>
      <Box
        id="add-comment-form"
        style={{ display: "flex", alignItems: "flex-start" }}
      >
        <TextField
          value={comment}
          onChange={handleCommentChange}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              submitComment();
            }
          }}
          sx={{ mr: 2, "& .MuiOutlinedInput-root": { borderRadius: "30px" } }}
          style={{ width: "350px" }}
          label="Add a note..."
          helperText="Press Enter to submit"
          variant="outlined"
          multiline
          autoComplete="off"
          size="small"
          disabled={mutation.isPending}
          autoFocus={autofocus}
        />
      </Box>

      <Box id="the-comments-themselves">
        {target.comments
          .sort((a, b) => b.created - a.created)
          .map((comment) => (
            <CommentContainer
              key={comment.id}
              comment={comment}
              target={target}
            />
          ))}
      </Box>

      {mutation.error && (
        <Snackbar
          open={true}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Alert
            onClose={() => mutation.reset()}
            severity="error"
            sx={{ width: "100%" }}
          >
            {mutation.error.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}

function CommentContainer({ comment, target }) {
  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: () => deleteComment(comment.id),
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: ["targets", target.search_uid, target.stage],
      });

      const previousTargets = queryClient.getQueryData([
        "targets",
        target.search_uid,
        target.stage,
      ]);

      // Optimistically remove the comment
      queryClient.setQueryData(
        ["targets", target.search_uid, target.stage],
        (old) =>
          old?.map((t) =>
            t.id === target.id
              ? {
                  ...t,
                  comments: t.comments.filter((c) => c.id !== comment.id),
                }
              : t
          )
      );

      // Simulate Enter press for the Comments Popper in Grid View
      const event = new KeyboardEvent("keydown", {
        key: "Enter",
        code: "Enter",
        keyCode: 13,
        which: 13,
        bubbles: true,
      });
      document.getElementById("comment-actions").dispatchEvent(event);

      return { previousTargets };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(
        ["targets", target.search_uid, target.stage],
        context.previousTargets
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["targets", target.search_uid, target.stage],
      });
    },
  });

  return (
    <Box
      sx={{ my: 4 }}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography
          variant="caption"
          color="lightgray"
          style={{ marginRight: "15px" }}
        >
          {comment.author}
        </Typography>
        <Typography variant="body">{comment.text}</Typography>
      </Box>

      <Box
        id="comment-actions"
        style={{ display: "flex", minWidth: "80px" }}
        sx={{ mx: 2 }}
      >
        <Typography id="time-ago" variant="caption" color="lightgray">
          {fromNow(comment.created)}
        </Typography>

        <Box id="edit" sx={{ ml: 1 }}>
          {!comment.id ? (
            // Show spinner until the comment is saved
            <CircularProgress size={20} color="textSecondary" />
          ) : (
            // Show delete icon once the comment has a real ID
            <ClearOutlined
              style={{
                color: "darkgray",
                cursor: "pointer",
                marginTop: "-2px",
                fontSize: "1em",
              }}
              onClick={() => deleteMutation.mutate()}
              sx={{ opacity: deleteMutation.isPending ? 0.5 : 1 }}
            />
          )}
        </Box>
      </Box>

      {deleteMutation.error && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert
            onClose={() => deleteMutation.reset()}
            severity="error"
            sx={{ width: "100%" }}
          >
            {deleteMutation.error.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}
