import { Clear } from "@mui/icons-material";
import { Box, Button, Card, Stack } from "@mui/material";
import {
  DataGridPremium,
  GridColumnMenu,
  GridFilterPanel,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarExport,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useEffect, useMemo, useState, useCallback } from "react";
import { getColumns } from "./columns";
import { useTargetMutation } from "@/services/mutations";

import MoveTargets from "./MoveTargets";

// Default models
const DEFAULT_FILTER_MODEL = {
  items: [
    {
      field: "fit",
      operator: "not",
      value: "Clear reject",
    },
  ],
};
const DEFAULT_SORT_MODEL = [
  {
    field: "domain",
    sort: "asc",
  },
];
const DEFAULT_COL_VIS_MODEL = {};
const DEFAULT_COL_ORDER = [];
const DEFAULT_COL_WIDTHS = {};

export default function TargetGridContainer({ targets, searchData }) {
  const apiRef = useGridApiRef();
  const mutation = useTargetMutation();
  const baseColumns = getColumns(searchData);

  // Local state
  const [selectionModel, setSelectionModel] = useState([]);
  const [filterModel, setFilterModel] = useState(DEFAULT_FILTER_MODEL);
  const [sortModel, setSortModel] = useState(DEFAULT_SORT_MODEL);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    DEFAULT_COL_VIS_MODEL
  );
  const [columnOrder, setColumnOrder] = useState(DEFAULT_COL_ORDER);
  const [columnWidths, setColumnWidths] = useState(DEFAULT_COL_WIDTHS);

  // Load state from localStorage
  useEffect(() => {
    const keys = {
      filterModel: [setFilterModel, (v) => v?.items],
      sortModel: [setSortModel, Array.isArray],
      columnVisibilityModel: [
        setColumnVisibilityModel,
        (v) => typeof v === "object",
      ],
      columnOrder: [setColumnOrder, Array.isArray],
      columnWidths: [setColumnWidths, (v) => typeof v === "object"],
    };

    Object.entries(keys).forEach(([key, [setter, validator]]) => {
      try {
        const saved = JSON.parse(localStorage.getItem(key));
        if (saved && validator(saved)) setter(saved);
      } catch (err) {
        console.error(`Storage parse error for ${key}:`, err);
      }
    });
  }, []);

  // Save state to localStorage
  const persistState = useCallback((key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      persistState("filterModel", filterModel);
      persistState("sortModel", sortModel);
      persistState("columnVisibilityModel", columnVisibilityModel);
      persistState("columnOrder", columnOrder);
      persistState("columnWidths", columnWidths);
    }, 300);
    return () => clearTimeout(timer);
  }, [filterModel, sortModel, columnVisibilityModel, persistState]);

  const handleColumnOrderChange = () => {
    const cols = apiRef.current.getAllColumns();
    const newColOrder = cols.map((c) => c.field);
    persistState("columnOrder", newColOrder);
  };

  const handleColumnResize = () => {
    const cols = apiRef.current.getAllColumns();
    const newWidths = cols.reduce((acc, c) => {
      if (c.field) acc[c.field] = c.width;
      return acc;
    }, {});
    persistState("columnWidths", newWidths);
  };

  // Merge and order columns
  const mergedColumns = useMemo(() => {
    const columnMap = baseColumns.reduce((acc, col) => {
      acc[col.field] = { ...col, width: columnWidths[col.field] ?? col.width };
      return acc;
    }, {});

    const ordered = columnOrder
      .filter((field) => columnMap[field])
      .map((field) => columnMap[field]);

    const leftover = baseColumns
      .filter((c) => !columnOrder.includes(c.field))
      .map((c) => ({ ...c, width: columnWidths[c.field] ?? c.width }));

    return [...ordered, ...leftover];
  }, [baseColumns, columnOrder, columnWidths]);

  // Row update handler
  const handleUpdate = async (newRow, oldRow) => {
    const changes = Object.entries(newRow).reduce((acc, [key, value]) => {
      if (oldRow[key] !== value) acc[key] = value;
      return acc;
    }, {});

    if (Object.keys(changes).length === 0) return oldRow;

    try {
      mutation.mutateAsync({
        searchUid: searchData.uid,
        stage: oldRow.stage,
        domain: newRow.domain,
        data: changes,
      });
      return newRow;
    } catch (error) {
      console.error("Error updating row:", error);
      return oldRow;
    }
  };

  return (
    <Box
      sx={{
        mt: 1,
        mb: 2,
        mr: "100px",
        "& .MuiDataGrid-root": { borderColor: "rgba(19,19,19)" },
      }}
    >
      {selectionModel.length > 0 && <MoveTargets apiRef={apiRef} />}
      <Box sx={{ height: "calc(100vh - 220px)", width: "100%" }}>
        <DataGridPremium
          apiRef={apiRef}
          rows={targets}
          getRowId={(row) => row.domain}
          columns={mergedColumns}
          density="compact"
          processRowUpdate={handleUpdate}
          onProcessRowUpdateError={(error) => console.error(error)}
          slots={{ toolbar: CustomToolbar }}
          pagination
          pageSizeOptions={[10, 20, 50, 100]}
          checkboxSelection
          selectionModel={selectionModel}
          onRowSelectionModelChange={(newSelection) =>
            setSelectionModel(newSelection)
          }
          filterModel={filterModel}
          onFilterModelChange={setFilterModel}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={setColumnVisibilityModel}
          onColumnResize={handleColumnResize}
          onColumnOrderChange={handleColumnOrderChange}
          disableRowSelectionOnClick
          cellSelection
        />
      </Box>
    </Box>
  );
}

function CustomToolbar() {
  const handleReset = () => {
    localStorage.removeItem("filterModel");
    localStorage.removeItem("sortModel");
    localStorage.removeItem("columnVisibilityModel");
    localStorage.removeItem("columnOrder");
    localStorage.removeItem("columnWidths");
    window.location.reload();
  };

  return (
    <Stack direction="row" justifyContent="space-between" sx={{ mb: 1 }}>
      <Card sx={{ p: 1 }}>
        <GridFilterPanel />
      </Card>

      <Stack direction="row" spacing={1}>
        <GridToolbarColumnsButton />
        <GridToolbarExport />
        <Button size="small" startIcon={<Clear />} onClick={handleReset}>
          Reset
        </Button>
      </Stack>
    </Stack>
  );
}
