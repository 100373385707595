import { useSearchParams } from "react-router-dom";
import GoogleSearchDrawer from "@/components/GoogleSearchDrawer";
import GrataDrawer from "@/components/GrataDrawer";
import ImportDrawer from "@/components/ImportDrawer";
import NotesDrawer from "@/components/NotesDrawer";
import { Box, Chip } from "@mui/material";
import { useSearchData } from "@/services/hooks";

export default function RightNav() {
  const [searchParams] = useSearchParams();
  const { data: searchData } = useSearchData({
    uid: searchParams.get("searchKey"),
  });

  return (
    <Box>
      <Box
        id="right-drawer-main"
        sx={{
          position: "fixed",
          backgroundColor: "rgba(19,19,19)",
          top: 70,
          right: 20,
          display: "flex",
          flexDirection: "column",
          gap: 5,
          height: "100vh",
          width: 20,
          alignItems: "center",

          py: 3,
        }}
      >
        {searchData && (
          <>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
              <NotesDrawer searchData={searchData} />
              <GrataDrawer searchData={searchData} />

              <ImportDrawer searchData={searchData} />
              <GoogleSearchDrawer searchData={searchData} />
            </Box>
            <Box>
              <Chip
                label={searchData.analyst?.name || ""}
                variant="outlined"
                color="info"
                size="small"
                sx={{
                  position: "fixed",
                  bottom: 10,
                  right: 10,
                }}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
