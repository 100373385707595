import { useState } from "react";
import { Box, CircularProgress, Stack, Typography, Chip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useSearches } from "@/services/hooks";
import { fromNow } from "@/utils/time";

export default function SearchBox() {
  const [value, setValue] = useState(null);
  const { data: searches, isLoading, isError } = useSearches();

  const handleSelect = (event, newValue) => {
    if (newValue) {
      window.location.href = `/search?searchKey=${newValue.uid}&currentView=land`;
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Typography color="error" sx={{ mt: 2 }}>
        Error loading searches. Please try again later.
      </Typography>
    );
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
      <Autocomplete
        value={value}
        onChange={handleSelect} // Navigate when an option is selected
        options={searches || []}
        // groupBy={(option) => option.status || "Uncategorized"} // Group by status
        // getOptionLabel={(option) => option.label || ""}
        sx={{ width: 400 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Find Search"
            variant="outlined"
            sx={{ borderRadius: "30px" }}
          />
        )}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            <Result
              label={option.label}
              status={option.status}
              updated={option.updated}
              analyst={option.analyst}
              lead={option.lead}
            />
          </Box>
        )}
        // renderGroup={(params) => (
        //   <Box key={params.key} sx={{ p: 2 }}>
        //     <Typography variant="h6" color="info">
        //       {params.group.toUpperCase()}
        //     </Typography>
        //     {params.children}
        //   </Box>
        // )}
      />
    </Box>
  );
}

function Result({ label, status, updated, analyst, lead }) {
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <Stack direction="column" spacing={0.5}>
        <Typography variant="body1" sx={{ fontSize: "0.9rem" }}>
          {label}
        </Typography>

        <Typography variant="caption" color="text.secondary">
          {analyst.name}
          {lead.name && `, ${lead.name}`}
        </Typography>
      </Stack>

      <Stack direction="column" spacing={0.5} alignItems="center">
        <Chip label={status} size="small" color="info" variant="outlined" />
        <Typography variant="caption" color="text.secondary">
          {fromNow(updated)}
        </Typography>
      </Stack>
    </Stack>
  );
}
