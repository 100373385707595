import { ICON_MAP, LABEL_MAP } from "@/fragments/Constants";
import { AccountTree, Cancel, CheckCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { moveTargets } from "@/services/api";

export default function ResultCard({
  searchUid,
  domain,
  currentStage,
  importStage,
  source = [],
}) {
  const [submitted, setSubmitted] = useState(false);
  const [display, setDisplay] = useState("flex");
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: () => moveTargets(searchUid, [domain], importStage),
    onSuccess: () => {
      setSubmitted(true);
      // Invalidate queries so data is refreshed
      queryClient.invalidateQueries(["targets", Number(searchUid)]);
      queryClient.invalidateQueries(["targetCount", Number(searchUid)]);
      queryClient.invalidateQueries(["domainStageMap", Number(searchUid)]);
    },
    onError: (error) => {
      console.error("Error moving target:", error);
    },
  });

  return (
    <Card
      sx={{
        my: 2,
        p: 2,
        display,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      {!submitted ? (
        <>
          <IconButton onClick={() => setDisplay("none")}>
            <Cancel color="info" />
          </IconButton>

          <Box>
            <Typography>{LABEL_MAP[currentStage]}</Typography>
          </Box>

          <Typography sx={{ fontWeight: 600 }}>{domain}</Typography>

          <Box>
            {isLoading ? (
              <CircularProgress size={30} />
            ) : (
              <Button
                variant="outlined"
                onClick={() => mutate()}
                endIcon={ICON_MAP[importStage]}
                color="secondary"
                sx={{ borderRadius: 20 }}
                size="small"
                disabled={currentStage === importStage}
              >
                Move to {LABEL_MAP[importStage]}
              </Button>
            )}
          </Box>
        </>
      ) : (
        // Success message after submission
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <CheckCircle color="success" />
          <Typography sx={{ fontWeight: 600 }}>{domain}</Typography>
          <Typography>
            {LABEL_MAP[currentStage]} → {LABEL_MAP[importStage]}
          </Typography>
          <Chip
            icon={<AccountTree />}
            color="primary"
            label={source.join(", ")}
            variant="outlined"
          />
        </Box>
      )}
    </Card>
  );
}
