import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  AppBar,
  Box,
  Chip,
  IconButton,
  Stack,
  Toolbar,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import {
  ArrowBackIos,
  GridView,
  IosShare,
  Share,
  SpeakerNotes,
  TableView,
  ViewList,
} from "@mui/icons-material";

import { ICON_MAP, LABEL_MAP } from "@/fragments/Constants";
import { useSearchData } from "@/services/hooks";
import NextResultsButton from "./NextResultsButton";
import SearchDomains from "@/components/SearchDomains";
import { getIcon } from "@/utils";

function DisplayToggle({ displayMode, setDisplayMode }) {
  useEffect(() => {
    localStorage.setItem("displayMode", displayMode);
  }, [displayMode]);

  const handleDisplayChange = (_, newMode) => {
    if (newMode !== null) {
      setDisplayMode(newMode);
    }
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography variant="caption" color="textSecondary">
        Display Mode
      </Typography>
      <ToggleButtonGroup
        value={displayMode}
        exclusive
        onChange={handleDisplayChange}
        size="small"
        aria-label="display mode"
      >
        <ToggleButton value="grid" aria-label="grid view">
          <TableView color={displayMode === "grid" ? "info" : "inherit"} />
        </ToggleButton>
        <ToggleButton value="card" aria-label="list view">
          <SpeakerNotes color={displayMode === "card" ? "info" : "inherit"} />
        </ToggleButton>
      </ToggleButtonGroup>
      <Typography variant="caption" color="info">
        {displayMode === "grid" ? "Grid" : "Notes"} View
      </Typography>
    </Stack>
  );
}

export default function PageAppBar({ displayMode, setDisplayMode }) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const searchKey = searchParams.get("searchKey");
  const currentView = searchParams.get("currentView");

  const { data: searchData } = useSearchData({ uid: searchKey });

  return (
    <AppBar variant="transparent" position="sticky">
      <Toolbar
        sx={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          pl: 0,
          gap: 4,
          flexDirection: {
            xs: "column",
            md: "row",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",

            gap: 2,
          }}
        >
          <IconButton onClick={() => navigate("/")} aria-label="back to search">
            <ArrowBackIos />
          </IconButton>

          <Stack direction="column" spacing={0} alignItems="flex-start">
            <Typography variant="body" sx={{ fontWeight: "bold" }}>
              {searchData?.label}
            </Typography>

            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ mt: 0.5 }}
            >
              {getIcon(currentView, "info")}
              <Typography variant="caption" color="textSecondary">
                {LABEL_MAP[currentView]}
              </Typography>
            </Stack>
          </Stack>

          {/* <Chip
            label={LABEL_MAP[currentView]}
            size="large"
            variant="outlined"
            sx={{ mr: 4 }}
          /> */}
          <IconButton color="auto" aria-label="share">
            <IosShare />
          </IconButton>
        </Box>

        <DisplayToggle
          displayMode={displayMode}
          setDisplayMode={setDisplayMode}
        />

        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{
            display: {
              md: "none",
              lg: "flex",
            },
          }}
        >
          <SearchDomains searchUid={searchKey} />
          <NextResultsButton />
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
